
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function repeatI1(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('div', { 'className': 'halo-item' }, _createElement('div', {
                    'className': 'product-card layout-1',
                    'data-product-id': 'product-' + this.id,
                    'data-id': this.id
                }, _createElement('div', { 'className': 'product-image' }, _createElement('a', {
                    'className': 'product_wishlist wishlist',
                    'href': '#',
                    'aria-label': 'wishlist',
                    'data-icon-wishlist': true,
                    'data-product-handle': this.handle,
                    'data-id': this.id
                }, _createElement('svg', { 'className': 'icon' }, _createElement('use', { 'xlinkHref': '#icon-heart' }))), _createElement('div', { 'className': 'product_badges' }, !this.out_of_stock && this.on_sale ? _createElement('div', {
                    'className': 'badge sale-badge sale-badge-2',
                    'key': '441'
                }, '\n          -', this.calcDiscount(this.price, this.compare_at_price), '%\n        ') : null, this.out_of_stock ? _createElement('div', {
                    'className': 'badge soldOut-badge',
                    'key': '626'
                }, 'Sold Out') : null), _createElement('a', {
                    'className': 'product-link' + (this.image2 ? ' image-swap' : ''),
                    'href': this.url,
                    'aria-label': 'link'
                }, _createElement('img', {
                    'className': (this.image2 ? 'image-one ' : '') + 'lazyautosizes lazyloaded',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'onError': this.onImageError
                }), this.image2 ? _createElement('img', {
                    'className': 'image-two lazyautosizes lazyloaded',
                    'src': this.resizeImage(this.image2),
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '1032'
                }) : null), !this.replace_buttons ? _createElement('a', {
                    'className': 'product-quickview',
                    'aria-label': 'quickview',
                    'id': this.handle,
                    'data-quickview': true,
                    'aria-describedby': 'a11y-external-message',
                    'key': '1219'
                }, '\n        Quick View\n      ') : null, _createElement('div', { 'className': 'product-action' }, !this.b2b_price_override_text ? _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'className': 'variants',
                    'id': 'list-product-form-' + this.id,
                    'data-id': 'product-actions-' + this.id,
                    'encType': 'multipart/form-data',
                    'key': '1454'
                }, this.variant_ids.length > 1 ? [_createElement('a', {
                        'className': 'btn product-btn',
                        'href': this.url,
                        'aria-label': 'button',
                        'key': '16581'
                    }, '\n              ', this.out_of_stock ? 'Sold out' : 'Select options', '\n            ')] : null, this.variant_ids.length === 1 ? [
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'id',
                        'value': this.variant_ids,
                        'key': '19261'
                    }),
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'quantity',
                        'value': '1',
                        'key': '19263'
                    }),
                    !(this.replace_buttons && !this.out_of_stock) ? _createElement('button', {
                        'data-btn-addtocart': true,
                        'className': 'btn product-btn',
                        'type': 'submit',
                        'data-form-id': '#list-product-form-' + this.id,
                        'disabled': this.out_of_stock,
                        'key': '19265'
                    }, '\n              ', this.out_of_stock ? 'Sold out' : 'Add to cart', '\n            ') : null,
                    this.replace_buttons && !this.out_of_stock ? _createElement('a', {
                        'className': 'btn product-btn',
                        'href': this.url,
                        'aria-label': 'button',
                        'key': '19267'
                    }, '\n              Learn More\n            ') : null
                ] : null) : null)), _createElement('div', { 'className': 'product-content' }, _createElement('div', { 'className': 'product-detail' }, _createElement('div', { 'className': 'product-vendor' }, _createElement('a', Object.assign({}, {
                    'href': this.vendor_url,
                    'title': this.removeHTML(this.vendor)
                }, { dangerouslySetInnerHTML: { __html: this.vendor } }))), _createElement('h4', { 'className': 'product-title' }, _createElement('a', Object.assign({}, {
                    'href': this.url,
                    'aria-label': 'title'
                }, { dangerouslySetInnerHTML: { __html: this.breakWordOnSlash(this.title) } }))), _createElement('div', { 'className': 'product-label' }, 'Black Friday ', _createElement('span', { 'className': 'product-label-highlight' }, 'Sale')), _createElement('div', { 'className': 'product-price' + (this.on_sale && !this.price_varies ? ' price--on-sale' : '') }, !this.b2b_price_override_text ? [
                    this.on_sale && !this.price_varies ? _createElement('span', {
                        'className': 'price-item price-item--regular',
                        'key': '33731'
                    }, _createElement('span', { 'className': 'money' }, this.formatPrice(this.compare_at_price))) : null,
                    _createElement('span', {
                        'className': 'price-item ' + (this.on_sale && !this.price_varies ? 'price-item--sale' : 'price-item--regular'),
                        'key': '33733'
                    }, this.price_varies ? _createElement('span', { 'key': '3798' }, 'From ') : null, _createElement('span', { 'className': 'money' }, this.formatPrice(this.price)))
                ] : null, this.b2b_price_override_text ? [_createElement('span', {
                        'className': 'price-item',
                        'key': '39691'
                    }, this.b2b_price_override_text)] : null), [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '41420'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI1.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], this.price && !this.b2b_price_override_text ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '4548'
                }) : null, this.body_html && this.view === '1' ? _createElement('div', mergeProps({
                    'className': 'product-description',
                    'key': '4729'
                }, { dangerouslySetInnerHTML: { __html: this.body_html } })) : null), _createElement('div', { 'className': 'product-actions' }, !this.b2b_price_override_text ? _createElement('form', {
                    'action': '/cart/add',
                    'method': 'post',
                    'className': 'variants',
                    'id': 'list-product-form-' + this.id,
                    'data-id': 'product-actions-' + this.id,
                    'encType': 'multipart/form-data',
                    'key': '4948'
                }, this.variant_ids.length > 1 ? [_createElement('a', {
                        'className': 'btn product-btn',
                        'href': this.url,
                        'aria-label': 'button',
                        'key': '51521'
                    }, '\n              ', this.out_of_stock ? 'Sold out' : 'Select options', '\n            ')] : null, this.variant_ids.length === 1 ? [
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'id',
                        'value': this.variant_ids,
                        'key': '54201'
                    }),
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'quantity',
                        'value': '1',
                        'key': '54203'
                    }),
                    !(this.replace_buttons && !this.out_of_stock) ? _createElement('button', {
                        'data-btn-addtocart': true,
                        'className': 'btn product-btn',
                        'type': 'submit',
                        'data-form-id': '#list-product-form-' + this.id,
                        'disabled': this.out_of_stock,
                        'key': '54205'
                    }, '\n              ', this.out_of_stock ? 'Sold out' : 'Add to cart', '\n            ') : null,
                    this.replace_buttons && !this.out_of_stock ? _createElement('a', {
                        'className': 'btn product-btn',
                        'href': this.url,
                        'aria-label': 'button',
                        'key': '54207'
                    }, '\n              Learn More\n            ') : null
                ] : null) : null, _createElement('button', {
                    'className': 'wishlist btn btn--secondary',
                    'data-icon-wishlist': true,
                    'data-product-handle': this.handle,
                    'data-id': this.id
                }, _createElement('span', { 'className': 'add' }, 'Add to Wish List'), _createElement('span', { 'className': 'remove' }, 'Remove Wish List'))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products container' }, _createElement('div', { 'className': 'section-header text-left cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []