import shopifySparkGenericDefaults from '../shopify-spark-generic/config.js';

const customerTagsExtra = window.Convermax?.config?.customerTags
  ? { customerTags: window.Convermax.config.customerTags }
  : {};

export default {
  includes: ['shopify-spark-generic'],
  ...shopifySparkGenericDefaults,
  fitmentSearch: {
    ...shopifySparkGenericDefaults.fitmentSearch,
    forceVehicleSelection: true,
  },
  SearchRequestDefaults: {
    ...shopifySparkGenericDefaults.SearchRequestDefaults,
    extra: {
      ...shopifySparkGenericDefaults.SearchRequestDefaults.extra,
      ...customerTagsExtra,
    },
  },
  autocomplete: {
    ...shopifySparkGenericDefaults.autocomplete,
    requestDefaults: {
      ...shopifySparkGenericDefaults.autocomplete.requestDefaults,
      extra: {
        ...shopifySparkGenericDefaults.autocomplete.requestDefaults.extra,
        ...customerTagsExtra,
      },
    },
  },
};
